/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
/* *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
} */

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove list styles (bullets/numbers) */
ol,
ul {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

body {
  font-family: 'Helvetica', sans-serif;
  direction: rtl;
  background-color: black;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: black;
  text-decoration: unset;
}

/* ANT */
.ant-pagination {
  display: flex;
  width: 100%;
  direction: ltr;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
}

.ant-pagination .ant-pagination-item {
  border-radius: 20px;
  /* background-color: ${(p) => THEMES[p.theme.themeName]?.paginationButton}; */
  background-color: red;
  color: white;
  border: none;
}

.ant-pagination li {
  border-radius: 20px;
  background-color: red;
  color: white;
  border: none;
  padding: 0px 15px;
}

.ant-pagination a {
  color: white;
  line-height: 2.3;
}

.ant-pagination .ant-pagination-item-active {
  border: 1px solid white;
}
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-item-link {
  background-color: unset !important;
}
